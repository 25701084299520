@tailwind base;
@tailwind components;
@tailwind utilities;

.jiggle {
  animation: jiggle linear;
  animation-duration: 250ms;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
